.react-flow__node-column,
.react-flow__node-rowCell,
.react-flow__node-bidi,
.react-flow__node-sheetCell,
.react-flow__node-postgresColumn,
.react-flow__node-i18n {
  width: 200px;
  text-align: center;
}

.react-flow__node {
  z-index: -1 !important;
}
